exports.components = {
  "component---src-components-consultant-tsx": () => import("./../../../src/components/Consultant.tsx" /* webpackChunkName: "component---src-components-consultant-tsx" */),
  "component---src-components-documents-tsx": () => import("./../../../src/components/Documents.tsx" /* webpackChunkName: "component---src-components-documents-tsx" */),
  "component---src-components-index-tsx": () => import("./../../../src/components/index.tsx" /* webpackChunkName: "component---src-components-index-tsx" */),
  "component---src-components-packages-bronze-tsx": () => import("./../../../src/components/Packages/Bronze.tsx" /* webpackChunkName: "component---src-components-packages-bronze-tsx" */),
  "component---src-components-packages-compare-tsx": () => import("./../../../src/components/Packages/Compare.tsx" /* webpackChunkName: "component---src-components-packages-compare-tsx" */),
  "component---src-components-packages-gold-tsx": () => import("./../../../src/components/Packages/Gold.tsx" /* webpackChunkName: "component---src-components-packages-gold-tsx" */),
  "component---src-components-packages-silver-tsx": () => import("./../../../src/components/Packages/Silver.tsx" /* webpackChunkName: "component---src-components-packages-silver-tsx" */),
  "component---src-components-packages-summary-tsx": () => import("./../../../src/components/Packages/Summary.tsx" /* webpackChunkName: "component---src-components-packages-summary-tsx" */),
  "component---src-components-packages-tsx": () => import("./../../../src/components/Packages.tsx" /* webpackChunkName: "component---src-components-packages-tsx" */),
  "component---src-components-receipt-tsx": () => import("./../../../src/components/Receipt.tsx" /* webpackChunkName: "component---src-components-receipt-tsx" */),
  "component---src-components-search-tsx": () => import("./../../../src/components/Search.tsx" /* webpackChunkName: "component---src-components-search-tsx" */),
  "component---src-components-subscribe-tsx": () => import("./../../../src/components/Subscribe.tsx" /* webpackChunkName: "component---src-components-subscribe-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/About.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-admin-tsx": () => import("./../../../src/pages/Admin/[...].tsx" /* webpackChunkName: "component---src-pages-admin-tsx" */),
  "component---src-pages-articles-absence-management-tsx": () => import("./../../../src/pages/Articles/Absence-Management.tsx" /* webpackChunkName: "component---src-pages-articles-absence-management-tsx" */),
  "component---src-pages-articles-employee-dismiss-tsx": () => import("./../../../src/pages/Articles/Employee-Dismiss.tsx" /* webpackChunkName: "component---src-pages-articles-employee-dismiss-tsx" */),
  "component---src-pages-articles-employer-branding-tsx": () => import("./../../../src/pages/Articles/Employer-Branding.tsx" /* webpackChunkName: "component---src-pages-articles-employer-branding-tsx" */),
  "component---src-pages-articles-grievances-tsx": () => import("./../../../src/pages/Articles/Grievances.tsx" /* webpackChunkName: "component---src-pages-articles-grievances-tsx" */),
  "component---src-pages-articles-hybrid-working-tsx": () => import("./../../../src/pages/Articles/Hybrid-Working.tsx" /* webpackChunkName: "component---src-pages-articles-hybrid-working-tsx" */),
  "component---src-pages-articles-team-motivation-tsx": () => import("./../../../src/pages/Articles/Team-Motivation.tsx" /* webpackChunkName: "component---src-pages-articles-team-motivation-tsx" */),
  "component---src-pages-articles-team-performance-tsx": () => import("./../../../src/pages/Articles/Team-Performance.tsx" /* webpackChunkName: "component---src-pages-articles-team-performance-tsx" */),
  "component---src-pages-articles-tsx": () => import("./../../../src/pages/Articles.tsx" /* webpackChunkName: "component---src-pages-articles-tsx" */),
  "component---src-pages-benefits-tsx": () => import("./../../../src/pages/Benefits/[...].tsx" /* webpackChunkName: "component---src-pages-benefits-tsx" */),
  "component---src-pages-blog-employment-law-04-03-2022-tsx": () => import("./../../../src/pages/Blog/Employment-Law-04-03-2022.tsx" /* webpackChunkName: "component---src-pages-blog-employment-law-04-03-2022-tsx" */),
  "component---src-pages-blog-employment-law-11-02-2022-tsx": () => import("./../../../src/pages/Blog/Employment-Law-11-02-2022.tsx" /* webpackChunkName: "component---src-pages-blog-employment-law-11-02-2022-tsx" */),
  "component---src-pages-blog-employment-law-18-03-2022-tsx": () => import("./../../../src/pages/Blog/Employment-Law-18-03-2022.tsx" /* webpackChunkName: "component---src-pages-blog-employment-law-18-03-2022-tsx" */),
  "component---src-pages-blogs-tsx": () => import("./../../../src/pages/Blogs.tsx" /* webpackChunkName: "component---src-pages-blogs-tsx" */),
  "component---src-pages-calculators-annual-leave-calculator-tsx": () => import("./../../../src/pages/Calculators/Annual-Leave-Calculator.tsx" /* webpackChunkName: "component---src-pages-calculators-annual-leave-calculator-tsx" */),
  "component---src-pages-calculators-hourly-leave-calculator-tsx": () => import("./../../../src/pages/Calculators/Hourly-Leave-Calculator.tsx" /* webpackChunkName: "component---src-pages-calculators-hourly-leave-calculator-tsx" */),
  "component---src-pages-calculators-maternity-pay-calculator-tsx": () => import("./../../../src/pages/Calculators/Maternity-Pay-Calculator.tsx" /* webpackChunkName: "component---src-pages-calculators-maternity-pay-calculator-tsx" */),
  "component---src-pages-calculators-redundancy-pay-calculator-tsx": () => import("./../../../src/pages/Calculators/Redundancy-Pay-Calculator.tsx" /* webpackChunkName: "component---src-pages-calculators-redundancy-pay-calculator-tsx" */),
  "component---src-pages-calculators-tsx": () => import("./../../../src/pages/Calculators.tsx" /* webpackChunkName: "component---src-pages-calculators-tsx" */),
  "component---src-pages-consultant-portal-tsx": () => import("./../../../src/pages/Consultant/Portal/[...].tsx" /* webpackChunkName: "component---src-pages-consultant-portal-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/Contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-cookies-policy-tsx": () => import("./../../../src/pages/Cookies-Policy.tsx" /* webpackChunkName: "component---src-pages-cookies-policy-tsx" */),
  "component---src-pages-document-tsx": () => import("./../../../src/pages/Document.tsx" /* webpackChunkName: "component---src-pages-document-tsx" */),
  "component---src-pages-employee-onboarding-tsx": () => import("./../../../src/pages/Employee/Onboarding.tsx" /* webpackChunkName: "component---src-pages-employee-onboarding-tsx" */),
  "component---src-pages-employee-portal-tsx": () => import("./../../../src/pages/Employee/Portal/[...].tsx" /* webpackChunkName: "component---src-pages-employee-portal-tsx" */),
  "component---src-pages-employment-status-tsx": () => import("./../../../src/pages/EmploymentStatus/[...].tsx" /* webpackChunkName: "component---src-pages-employment-status-tsx" */),
  "component---src-pages-feature-tsx": () => import("./../../../src/pages/Feature.tsx" /* webpackChunkName: "component---src-pages-feature-tsx" */),
  "component---src-pages-features-tsx": () => import("./../../../src/pages/Features.tsx" /* webpackChunkName: "component---src-pages-features-tsx" */),
  "component---src-pages-job-tsx": () => import("./../../../src/pages/Job.tsx" /* webpackChunkName: "component---src-pages-job-tsx" */),
  "component---src-pages-jobs-tsx": () => import("./../../../src/pages/Jobs.tsx" /* webpackChunkName: "component---src-pages-jobs-tsx" */),
  "component---src-pages-login-corporate-tsx": () => import("./../../../src/pages/Login/Corporate.tsx" /* webpackChunkName: "component---src-pages-login-corporate-tsx" */),
  "component---src-pages-login-employee-tsx": () => import("./../../../src/pages/Login/Employee.tsx" /* webpackChunkName: "component---src-pages-login-employee-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/Login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-portal-tsx": () => import("./../../../src/pages/Portal/[...].tsx" /* webpackChunkName: "component---src-pages-portal-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/Privacy-Policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-recover-password-tsx": () => import("./../../../src/pages/Recover-Password.tsx" /* webpackChunkName: "component---src-pages-recover-password-tsx" */),
  "component---src-pages-reference-request-tsx": () => import("./../../../src/pages/Reference/Request.tsx" /* webpackChunkName: "component---src-pages-reference-request-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/Register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-pages-success-tsx": () => import("./../../../src/pages/Success.tsx" /* webpackChunkName: "component---src-pages-success-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/Terms-and-Conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */)
}

